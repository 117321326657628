import React, {useState, useEffect, useMemo} from 'react';
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import { AppBar, Toolbar, CssBaseline, Button, Container, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";
import axios from 'axios';

import SignIn from './components/SignIn';
import DialogBox from './components/DialogBox';

import AppContext from './components/AppContext';
import Messages from './utils/Messages';
import Config from './utils/Config';

import { ROLES, PAGES } from './utils/Constants.js';

import './main.css';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [dialogBox, setDialogBox] = useState({show: false, type: null, title: null, message: null});

  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            userData: action.userData,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            userData: null,
          };
      }
    },
    {
      isSignout: true,
      userToken: null,
      userData: null,
      /*
      isSignout: false,
      userToken: 'development_token',
      userData: {"userId":1,"client":{"clientId":1,"name":"test client1","fullName":"puno ime2","type":"Zakupac","idNumber":"3","jmbgNumber":"4","taxNumber":"43434335","legalForm":"Preduzetnik","taxSystem":"Aktivan","contactPerson":"Pera Peric666666","address":"Radnicka 2277777","city":"8888Beograd88","zipCode":"99999","country":"Srbija00000","email":"sasa@ss.dd","phoneNumber1":"0653232bbbbb","mobileNumber1":"064645455ccccc","roadAssistanceDomestic":"080032233","roadAssistanceForeign":"+381224435454","workingHours":"9-17hfffff","note":"testgggggg","synced":false,"created":"Sep 7, 2023, 10:43:26 AM"},"role":{"roleId":4,"name":"Super Administrator"},"firstName":"aaaaa1","lastName":"milovic282","email":"admin","phone":"admin","password":"test","token":"development_token","hash":"ddsdsd","active":true,"lastSeen":"Sep 22, 2023, 3:17:37 PM","created":"Sep 7, 2023, 8:06:17 AM","platform":"android,33,1.4.0"}
    */
    }
  );

  const appContext = React.useMemo(() => ({
    userToken: state.userToken,
    userData: state.userData,
    signIn: async (username, password) => {
      // In a production app, we need to send some data (usually username, password) to server and get a token
      // We will also need to handle errors if sign in failed
      // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage

      console.log('auth sign in', username, password);
      //console.log('token:',state.userToken);

      if (username == '' || password == '') {
        setDialogBox({show: true, type: 'message', title: null, message: Messages.TXT_FILL_REQUIRED_FIELDS});
      } else try {
        setDialogBox({show: true, type: 'loading'});

        const response = await axios.get(Config.BASE_URL + `/admin/login?email=${username}&password=${password}`, {
          headers: {
            'Cache-Control': 'no-cache',
          },
        });

        console.log(response);

        if (!response.data?.result) {
          setDialogBox({show: true, type: 'message', title: null, message: "Korisnik nije pronađen"});
        } else {
          const userData = JSON.parse(response.data.content);

          setDialogBox({show: false});
          dispatch({ type: 'SIGN_IN', token: response.data.token, userData: userData });
        }
      } catch(error){
          console.error(error);
      }
      
    },
    signOut: async () => {
      console.log("SIGN_OUT")
      
      if (state.isSignout == false) {
        dispatch({ type: 'SIGN_OUT' });
      }
    },
  }),[state]
  );

  // overriding console.log in production
  if(Config.developmentMode == false) {
    console.log = function(){}; // turn off console logging
  }

  console.log('APP')

  console.log(location.pathname)
  console.log("isSignout:"+state.isSignout)

  const page = location.pathname.match(/\/([^/]+)\/.*/);

  const pages = [];

  if (!state.isSignout) {
    let allowed = false;

    if (location.pathname != "/") { // check does the user have permission for requested page
      for (let i in PAGES) {
        if (PAGES[i].path == page[1]) {
          if (PAGES[i].permissions.includes(appContext.userData.role.roleId)) {
            allowed = true;
          }
          break;
        }
      }
    }

    if (!allowed || location.pathname == "/") { // send user to allowed page
      for (let i in PAGES) {
        if (PAGES[i].permissions.includes(appContext.userData.role.roleId)) {  
          return (<Navigate to={PAGES[i].path + '/list'} />);
        }
      }
    }

    for (let i in PAGES) { // populate menu with allowed pages
      if (PAGES[i].permissions.includes(appContext.userData.role.roleId)) {
        pages.push(<Button key={i} className={PAGES[i].path == page[1] ? "MuiButton-active" : ""} color="inherit" onClick={() => {navigate(PAGES[i].path + '/list')}}>{PAGES[i].name}</Button>)
      }
    }  
  } 

  if (state.isSignout) {
    return (
      <AppContext.Provider value={{appContext, dialogBox, setDialogBox }}>
        <main>
          <DialogBox />    
          <SignIn />
        </main>
      </AppContext.Provider>  
  );
} else {
    return (
      <AppContext.Provider value={{appContext, dialogBox, setDialogBox }}>
          <CssBaseline />
          {(Config.developmentMode || state.userToken == 'development_token') && (<div style={{backgroundColor: 'red', color: 'white', textAlign: 'center'}}>DEVELOPMENT MODE</div>)}
          <AppBar position="static">
            <Toolbar>
              {pages}
              <Button color="inherit" onClick={() => { navigate('/'); appContext.signOut(); }}>Izloguj se</Button>
            </Toolbar>
          </AppBar>
          <main>
            <Container maxWidth="false">
              <DialogBox />    
              <Outlet />
            </Container>
          </main>
        </AppContext.Provider>  
    );
  }
}