var developmentMode = false;

if (developmentMode) {
    // development
    var server = 'http://localhost:8080';
} else {
    // production
    var server = 'https://mytrio.fleetmt.com';
}

const Config = {
    BASE_URL: server + '/api',
    developmentMode: developmentMode,
}

export default Config;