import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, IconButton, Icon } from '@mui/material';
import {useForm, FormErrorProvider, FormContainer, TextFieldElement, SelectElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';

import Config from '../../utils/Config';

import { areStringsEqual } from '../../utils/Helpers';

import { ROLES } from '../../utils/Constants.js';

export default function Add() {
  const navigate = useNavigate();
  const { appContext, setDialogBox } = React.useContext(AppContext);

  const formContext = useForm({
    defaultValues: {
      'clientId': 1,
      'roleId': ROLES.CLIENT,
      'active': true,
    }
  });

  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(appContext.userData.role.roleId != ROLES.SUPERADMINISTRATOR ? appContext.userData.client.clientId : null);

  const fetchCarsByClient = async (clientId) => {
    try {
      setLoading(true);

      const response = await axios.get(Config.BASE_URL + '/admin/cars/listByClient?id=' + clientId, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      setLoading(false);

      var cars = []

      if (response.data) {
        {response.data.map((item) => 
          cars.push({id: item.carId, label: item.garageNumber + ' - ' + item.registrationPlate + ' - ' + item.brand.name + ' ' + item.model})
        )}

        setCars(cars);
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  const fetchCars = async () => {
    try {
      const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      for (const dataId in response.data) {
        response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"] + " - " + response.data[dataId]["brandName"] + " " + response.data[dataId]["model"];
      }

      setCars(response.data);
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/clients/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const data in response.data) {
          response.data[data]["label"] = response.data[data]["name"] + " - " + (response.data[data]["idNumber"] ? response.data[data]["idNumber"] : response.data[data]["jmbgnumber"]);
        }

        setClients(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/getRoles', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        var roles = []

        if (response.data) {
          {response.data.map((item) => 
            roles.push({id: item.roleId, label: item.name, disabled: appContext.userData.role.roleId != ROLES.SUPERADMINISTRATOR && item.roleId == ROLES.SUPERADMINISTRATOR ? true : false})
          )}

          setRoles(roles);
        }
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchClients();
    fetchRoles();
    fetchCars();

  }, []);

  useEffect(() => {
    if (clientId) {
      fetchCarsByClient(clientId);
    } else {
      fetchCars();
      formContext.setValue('carId', null);
    }
  }, [clientId]);

  const handleFormSubmit = async (formData) => {
    console.log("handleFormSubmit")
    console.log(formData)

    formData.phone = formData.phone.replace(/^\+/, ''); // remove + from beginning
    formData.phone = formData.phone.replace(/^0/, '381'); // replace 0 with international

    if (!areStringsEqual(formData.newPassword, formData.newPassword2)) {
      setDialogBox({show: true, type: 'message', title: null, message: "Unesite istu šifru u oba polja"});
    } else {
      try {
        const response = await axios.post(Config.BASE_URL + '/admin/users/add', formData, {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
          }
        });

        //console.log('Response:', response);

        if (response.data.result) {
          navigate('/users/list');
        } else {
          alert(response.data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    }
  };

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Dodavanje novog korisnika</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          formContext={formContext}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Kompanija: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="clientId"
                matchId
                fullWidth
                required
                options={clients}
                control={formContext.control}
                autocompleteProps={{
                  onChange: (e, value) => {
                    if (value) {
                      setClientId(value.id);
                    } else {
                      setClientId(null);
                    }
                  }
                }}     
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Odabrano vozilo:</Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                loading={loading}
                options={cars}
                control={formContext.control}
                autocompleteProps={{
                  onChange: (e, value) => {
                    if (!clientId && value) {
                      // set client company for selected car
                      formContext.setValue('clientId', value.clientId);
                      setClientId(value.clientId);
                    }
                  }
                }}      
                textFieldProps={{
                  InputProps: { size: "small" },
                }}        
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Telefon: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="phone" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>E-mail: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="email" size="small" type="email" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Šifra:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="newPassword" size="small" type="password" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ponovite šifru:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="newPassword2" size="small" type="password" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Aktivan:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="active"
              fullWidth
              size="small"
              options={[
                {
                  id: true,
                  label: 'DA',
                  
                },
                {
                  id: false,
                  label: 'NE',
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Uloga: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="roleId"
              fullWidth
              size="small"
              required
              options={roles}
              type="number"
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ime: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="firstName" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Prezime <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="lastName" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Adresa:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="address" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Grad:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="city" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Dodaj</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/users/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}